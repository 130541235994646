<template>
  <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
    <template #title>
      <a-row type="flex" align="middle">
        <a-col :span="24" :md="12">
          <h5 class="font-semibold m-0">API管理</h5>
        </a-col>
        <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
          <a-button type="primary" icon="plus" size="small" @click="addapi()">
            添加API
          </a-button>
        </a-col>
      </a-row>
    </template>
    <a-table :columns="columns" :data-source="tableData" :pagination="{pageSize: 10,}">

      <template slot="info" slot-scope="info">
        <div class="table-avatar-info">
          <a-avatar shape="square" :src="info.platform"/>
          <div class="avatar-info">
            <p class="m-0 font-regular text-muted">备注：</p>
            <div style="display: flex; align-items: center;">
              <h6 class="m-0" style="margin-right: 5px!important;">{{ info.api_name }}</h6>
              <a-icon type="edit" theme="twoTone" @click="showModal(info.id)"/>
            </div>
          </div>
        </div>
      </template>

      <template slot="flag" slot-scope="flag">
        <a-tag class="tag-status" :class="flag ? 'ant-tag-primary' : 'ant-tag-success'">
          {{ flag ? "模拟盘" : "实盘" }}
        </a-tag>
      </template>
      <template slot="status" slot-scope="status">
        <a-tag class="tag-status ant-tag-blue" v-if="status.is_readonly">
          只读
        </a-tag>
        <a-tag
            class="tag-status ant-tag-green"
            v-else
        >
          交易
        </a-tag>
      </template>

      <template slot="editBtn" slot-scope="row">
        <a-button type="link" :data-id="row.key" class="btn-edit">
          查看详情
        </a-button>
      </template>

      <template slot="stopBtn" slot-scope="row">
        <a-button type="link" :data-id="row.key" class="btn-edit" @click="deleteApi(row.key, row.status.value)">
          <!--        {{ row.status === 1 ? "终止跟单" : "" }}-->
          删除
        </a-button>
      </template>

    </a-table>

    <a-modal
        title="修改名称"
        :visible="visible"
        :confirm-loading="confirmLoading"
        cancelText="取消"
        okText="确认修改"
        :closable=false
        :width="450"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      <a-input v-model="api_name" placeholder="输入修改的名字" />
    </a-modal>
  </a-card>
</template>


<script>
import http from "../utils/http"

const key = 'updatable';

const columns = [
  {
    title: '交易所',
    dataIndex: 'info',
    scopedSlots: {customRender: 'info'},
  },
  {
    title: 'API KEY',
    dataIndex: 'api_key',
    scopedSlots: {customRender: 'api_key'},
  },

  {
    title: '创建时间',
    dataIndex: 'create_datetime',
    class: 'text-muted',
  },
  {
    title: 'API类型',
    dataIndex: 'flag',
    scopedSlots: {customRender: 'flag'},
  },
  {
    title: '权限',
    dataIndex: 'status',
    scopedSlots: {customRender: 'status'},
  },
  {
    title: '',
    scopedSlots: {customRender: 'stopBtn'},
    width: 50,
  },
];

function modifyString(str) {
  // 获取前6位和后6位
  const start = str.substring(0, 6);
  const end = str.substring(str.length - 6);

  // 中间使用 '*' 填充
  const stars = '*'.repeat(12);

  // 拼接字符串
  return `${start}${stars}${end}`;
}

function transformItem(item) {
  let imagePath;
  switch(item.platform) {
    case 1:
      imagePath = 'images/okx.png';
      break;
    case 2:
      imagePath = 'images/binance.png';
      break;
    case 3:
      imagePath = 'images/traderwagon.png';
      break;
      // 可以添加更多的条件
    default:
      imagePath = 'images/face-3.jpeg'; // 默认图片
  }
  return {
    key: String(item.id), // 使用项目的 'id' 字段
    info: {
      id: item.id,
      platform: imagePath,
      api_name: item.api_name,
    },
    api_key: modifyString(item.api_key),
    // api_key: item.api_key,
    flag: item.flag,
    status: {
      is_readonly: item.is_readonly,
      value:item.status
    },
    create_datetime: item.create_datetime.substring(2, 10).replaceAll('-', '/'),
  };
}


export default {
  data() {
    return {
      tableData: [],
      columns,

      // 修改名称
      api_name: '',
      api_id: '',
      visible: false,
      confirmLoading: false,
    };
  },
  methods: {
    // 修改curl名称
    showModal(id) {
      this.visible = true;
      this.api_id = id;
    },
    async handleOk() {
      this.confirmLoading = true;
      try {
        const response = await http.patch('/api/apiname/?token=' + localStorage.getItem("token"), {
          api_name: this.api_name,
          api_id: this.api_id,
        });

        if (response.data.code === 0) {
          this.visible = false;
          this.confirmLoading = false;
          this.$message.success({ content: '修改成功', key, duration: 2 });
          this.fetchData();
        } else {
          this.$message.error({ content: '修改失败', key, duration: 2 });
        }
      } catch (error) {
        this.$message.error({ content: '暂无数据', key, duration: 2 });
      }
    },
    handleCancel(e) {
      console.log('Clicked cancel button');
      this.visible = false;
    },

    async fetchData() {
      try {
        const token = localStorage.getItem("token");
        const response = await http.get("/api/apiadd/?token=" + token);
        const originalData = response.data.data;
        const tableData = originalData.map(item => transformItem(item));
        this.tableData = tableData; // 更新组件的数据属性
        console.log(response.data.data); // 现在这里将正确打印出数据
      } catch (error) {
        console.error("请求失败:", error);
      }
    },
    deleteApi(id, status) {
      console.log(id, status);
      this.$confirm({
        title: '确认删除',
        // content: '您确定要删除这个API吗？',
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          return new Promise((resolve, reject) => {
            http.delete("/api/apiadd/" + id + "/?token=" + localStorage.getItem("token"))
                .then((response) => {
                  if (response.data.code === 0) {
                    this.fetchData(); // 删除成功后调用 fetchData
                    resolve();
                  } else {
                    this.$message.error(response.data.error);
                    resolve();
                  }
                })
                .catch(err => {
                  reject(err); // 错误处理
                });
          });
        },
        onCancel() {
          console.log('删除操作取消');
        },
      });
    },
    addapi() {
      this.$router.push({path: '/addapi'});
    }

  },
  mounted() {
    this.fetchData();
  }
};
</script>

<style lang="scss">
.ant-table-pagination.ant-pagination {
  margin-right: 25px;
  margin-left: 25px;
}
</style>