<template>
  <a-card :bordered="false" class="card-product header-solid mb-24">
    <div>
      <a-row :gutter="24" type="flex" justify="center" align="middle">
        <a-alert message="抢位功能说明" type="info" banner style="width: 55%;margin-bottom: 20px;"
                 description="本功能VIP免费使用！系统将以秒级自动帮你抢位，全程不超过1秒。一般2-3天内就可以抢到。包抢进，具体时间根据带单员有空位而定，总之抢到为止！"
        />
      </a-row>
      <a-row :gutter="24" type="flex" justify="center" align="middle">
        <a-timeline pending="查看抢位状态...">
          <a-timeline-item :color="no_data? 'gray' : 'green'">
            <a-icon slot="dot" type="check-circle" style="font-size: 18px;font-weight: bold;background-color: #FFFFFF;" v-if="!no_data"/>
            <h5>第一步：添加交易所Cookie信息</h5>
            <p>添加/更新Cookie信息：<router-link to="/speedcopy/curl">点击前往<a-icon type="link" theme="outlined" /></router-link></p>
            <a-table
                :columns="curl_columns"
                :data-source="curl_list"
                :pagination="false"
                style="width: 560px;margin-top: 20px;margin-bottom: 10px;border: 1px solid #ddd;border-radius: 8px;"
            >

              <template slot="info" slot-scope="info">
                <div style="display: flex; align-items: center;">
                  <h6 class="m-0" style="margin-right: 5px!important;">{{info.curl_name}}</h6>
                </div>
              </template>

              <template slot="updated_at" slot-scope="updated_at">
                {{updated_at}}
              </template>

              <template slot="available" slot-scope="available">
                <div v-if="!available">
                  <a-tag class="tag-status ant-tag-red">
                    已失效
                  </a-tag>
                </div>
                <div v-else>
                  <a-tag class="tag-status ant-tag-green">
                    有效
                  </a-tag>
                </div>
              </template>

            </a-table>
            <p style="font-weight: 600; font-size: 12px; color: #eb4b6d;">
              <a-icon type="warning" theme="outlined" />
              提示：币安账号必须至少每4天退出登录后再重新登陆，获取新的Cookie信息，否则Cookie信息会失效。
            </p>
          </a-timeline-item>

          <a-timeline-item :color="uniqueName === ''? 'gray' : 'green'">
            <a-icon slot="dot" type="check-circle" style="font-size: 18px;font-weight: bold;background-color: #FFFFFF;" v-if="uniqueName !== ''"/>
            <h5>第二步：选择抢位项目</h5>
            <a-form-item class="mb-10" label="选择交易所" :colon="false">
              <a-select v-model="exchange" default-value="2" style="width: 300px" @change="handleChange">
                <a-select-option value="2">币安Binance</a-select-option>
                <a-select-option value="1">欧意OKX</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item class="mb-10" label="输入项目ID" :colon="false" v-if="exchange === '2'">
              <a-input v-model="uniqueName" placeholder="eg. 3887627985594221568" style="width: 300px"/>
            </a-form-item>
            <a-form-item class="mb-10" label="输入项目ID" :colon="false" v-else>
              <a-input v-model="uniqueName" placeholder="eg. EEA569A04A98D4E9" style="width: 300px"/>
            </a-form-item>
            <p>PC访问交易员主页，在域名末尾，查看交易员ID</p>
            <p style="width: 550px" v-if="exchange === '2'">eg. https://www.binance.com/zh-CN/copy-trading/lead-details/3887627985594221568?timeRange=7D</p>
            <p style="width: 550px" v-else>eg.https://www.okx.com/cn/copy-trading/account/EEA569A04A98D4E9?tab=swap</p>
            <a-button type="primary" size="small" style="margin-top: 10px" v-if="uniqueName !== ''" @click="clickButton">
              跟单抢位
            </a-button>
          </a-timeline-item>
        </a-timeline>
      </a-row>
    </div>
  </a-card>
</template>

<script>
import http from "../utils/http"

const key = 'updatable';

const curl_columns = [
  {
    title: '名称',
    dataIndex: 'info',
    width: '25%',
    scopedSlots: { customRender: 'info' },
  },
  {
    title: '交易所',
    dataIndex: 'exchange',
    width: '25%',
    scopedSlots: { customRender: 'exchange' },
  },
  {
    title: '上次更新时间',
    dataIndex: 'updated_at',
    width: '25%',
    scopedSlots: { customRender: 'updated_at' },
  },
  {
    title: '是否有效',
    dataIndex: 'available',
    width: '25%',
    scopedSlots: {customRender: 'available'},
  },
]

function transformCurlItem(item) {
  if(item.exchange === 1){
    item.exchange = '欧意OKX';
  }else if (item.exchange === 2){
    item.exchange = '币安Binance';
  }
  return {
    info: {
      curl_id: item.curl_id,
      curl_name: item.curl_name,
    },
    exchange: item.exchange,
    updated_at: item.updated_at,
    available: item.available,
  }
}

export default {
  name: "Grab_Task",
  data() {
    return {
      // curl
      no_data: true,
      curl_columns,
      curl_list: [],

      uniqueName: '',
      exchange: '2',
    }
  },
  created() {
    this.fetchCurlData();
  },
  methods: {
    // 跳转
    clickButton() {
      // 如果uniqueName内有特殊符号，报错
      if (/[`~!@#$%^&*()_+<>?:"{},.\/;'[\]]/.test(this.uniqueName)) {
        this.$message.error('请输入正确的项目ID');
        return;
      }
      if (this.exchange === '2') {
        const path = '/grab-task/bn-grab/'+this.uniqueName
        this.$router.push({path: path});
      } else {
        const path = '/grab-task/okx-grab/'+this.uniqueName
        this.$router.push({path: path});
      }
    },
    // 获取curl信息
    async fetchCurlData() {
      this.$message.loading({ content: '请稍后...', key, duration: 0 });
      try {
        const response = await http.get('/api/curl/?token=' + localStorage.getItem("token"));

        if (response.data.code === 0) {
          // 更新用户数据
          const data = response.data.data;
          this.curl_list = data.map(item => transformCurlItem(item));
          this.no_data = false;
          this.$message.success({ content: '数据加载成功', key, duration: 2 });
        } else {
          this.$message.error({ content: response.data.error, key, duration: 2 });
        }
      } catch (error) {
        this.$message.error({ content: '暂无数据', key, duration: 2 });
      }
    },
  }
}
</script>

<style scoped>
.content {
  height: 100vh;
}
</style>