<template>
  <a-card :bordered="false" class="card-product header-solid mb-24">
    <template #title>
      <a-button type="primary" @click="() => $router.push({ path: '/grab-task' })" ghost><span style="margin-left: 0;">返回</span></a-button>
    </template>
    <div>
      <a-row :gutter="24" type="flex" justify="center" align="middle" v-if="msg">
        <a-alert :message=msg type="error" banner style="width: 50%;margin-bottom: 20px;"/>
      </a-row>
      <a-row :gutter="24" type="flex" justify="center" align="middle" v-if="!nickname">
        <a-skeleton active avatar :paragraph="false" style="width: 30%;"/>
      </a-row>
      <a-row :gutter="24" class="leader" type="flex" justify="center" align="middle" v-else>
        <img :src=avatarUrl alt="" width="50%">
        <h3 style="margin-bottom: 0;">{{ nickname }}</h3>
      </a-row>
      <a-row :gutter="24" class="leader" type="flex" justify="center" align="middle">
        <span style="width: 50%;text-align: center;" v-if="desc">{{ desc }}</span>
        <a-skeleton active :title="false" style="width: 50%;" v-else/>
      </a-row>
      <a-row :gutter="24" type="flex" justify="center">
        <a-row :gutter="24" type="flex" justify="center"  class="pnl">
          <a-row :gutter="24" type="flex" justify="center" align="middle" style="margin: 0">
            <h6>7天盈亏(USDT)</h6>
            <span class="profit" v-if="pnl" :style="{ color: pnl >= 0 ? '#31bd65' : '#eb4b6d'}">{{ pnl }}</span>
            <a-skeleton active :paragraph="false" style="width: 60px;margin-left: 20px;" v-else/>
          </a-row>
          <a-row :gutter="24" type="flex" justify="center" align="middle" style="margin: 0">
            <h6>7天收益率&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6>
            <span class="profit" v-if="roi" :style="{ color: roi >= 0 ? '#31bd65' : '#eb4b6d'}">{{ roi }}%</span>
            <a-skeleton active :paragraph="false" style="width: 60px;margin-left: 20px;" v-else/>
          </a-row>
        </a-row>
      </a-row>
    </div>
    <div class="settings">
      <div class="setting-item">
        <h4>合约跟单设置</h4>
        <a-form
            :form="form"
            :hideRequiredMark="true"
            style="width: 550px"
            @submit.prevent="onSubmit"
        >
          <p>跟单金额：专门用于跟随该交易员的总投资金额，将从您的交易账户中隔离占用。</p>
          <a-form-item class="mb-10" label="跟单金额" :colon="false">
            <a-input :placeholder=Ratio_dec suffix="USDT"
                     v-decorator="['investAmount',{ rules: [
                             { required: true, message: '请输入跟单金额！' },
                             { min: 1, message: '长度不能少于1位！' },
                             { validator: (rule, value, callback) => {
                                const numberValue = parseFloat(value);
                                if (isNaN(numberValue) || !isFinite(numberValue)) {
                                  callback('请输入有效的数字！');
                                } else if (numberValue < fixRatioMinCopyAmount) {
                                  callback('输入数值必须大于等于' + fixRatioMinCopyAmount);
                                } else if (!/^\d+(\.\d+)?$/.test(value)) {
                                  callback('请输入标准的数字格式，不要包含非数字字符');
                                }else {
                                  callback();
                                }
                              },
                              // message: '输入数值必须大于5',
                              trigger: 'change'
                            },
                          ]
                         },]"/>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" style="margin-top: 10px;width: 550px" disabled v-if="msg || !nickname">
              开始抢位
            </a-button>
            <a-button type="primary" html-type="submit" :loading="loading" style="margin-top: 10px;width: 550px" v-else>
              开始抢位
            </a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </a-card>

</template>

<script>
import http from "../utils/http"

export default {
  name: "Bn_Grab",
  data() {
    return {
      uniqueName: '',
      msg: '',
      nickname: '',
      avatarUrl: '',
      desc: '',
      roi: '',
      pnl: '',
      fixRatioMinCopyAmount: '',

      form: this.$form.createForm(this),
      costPerOrder: 0,
      investAmount: '',
      Ratio_dec: '0',
      PerOrder_dec: '0',
      loading: false,
    }
  },
  created() {
    // 跟单项目id
    const uniqueName = this.$route.params.uniqueName;
    this.uniqueName = uniqueName;
    this.fetchData(uniqueName);
  },
  methods: {
    // 获取交易员信息
    async fetchData(id) {
      const response = await http.post('/api/leader-info/?token=' + localStorage.getItem("token"), {uniqueName: id, exchange: 1});
      if (response.data.code == 0) {
        this.nickname = response.data.data.detail.nicknameTranslate;
        this.avatarUrl = response.data.data.detail.avatarUrl;
        if (this.avatarUrl === 'https://bin.bnbstatic.com/static/images/copytrading/default-avatar.png') {
          this.avatarUrl = '/images/head/default-avatar.png';
        }
        this.desc = response.data.data.detail.descTranslate;
        this.roi = response.data.data.performance.roi;
        this.pnl = response.data.data.performance.pnl;
        this.fixRatioMinCopyAmount = response.data.data.limit_info.fixRatioMinCopyAmount;

        this.Ratio_dec = '最低' + this.fixRatioMinCopyAmount ;
      }else {
        this.msg = response.data.msg;
        console.log(response.data.msg);
      }
    },

    // 提交表单
    onSubmit() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.loading = true;
          // 这里处理表单提交逻辑，例如发送请求
          // console.log('Submitted values:', values);
          const data = {
            exchange: 1,
            uniqueName: this.uniqueName,
            nickname: this.nickname,
            costPerOrder: 0,
            investAmount: values.investAmount,
          }
          console.log(data);
          await http.post('/api/grabtask/?token=' + localStorage.getItem("token"), data).then(response => {
            // 处理响应数据
            if (response.data.code === 0) {
              this.$message.success('任务创建成功！');
              this.$router.push({path: '/grablist'});
            } else {
              this.$message.error(response.data.error);
            }
            console.log(response.data);
          }).catch(error => {
            // 处理错误
            this.$message.error('任务创建失败，请联系客服处理！');
            console.error(error);
          });
          // 提交后重置 loading 状态
          this.loading = false;
        }
      });
    },
  }
}
</script>

<style scoped>
.leader{
  margin: 20px 0;
}
.leader img{
  width: 45px;
  height: 45px;
  border-radius: 10px; /* 圆角大小 */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* 可选的阴影效果 */
}
.leader h3{
  margin-left: 20px;
}
.pnl {
  flex-direction: column; /* 垂直方向布局 */
  align-items: flex-start;
  border: 1px solid #ccc;
  border-radius: 10px; /* 圆角大小 */
  width: 250px;
  padding: 10px 20px;
}
.pnl h6{
  margin: 0;
}
.profit {
  color: #00c853;
  font-size: 16px;
  font-weight: bold;
  margin-left: 20px;
}
.settings {
  margin: 40px 0;
  display: flex;
  justify-content: center;
}
.setting-item{
  /*margin: 20px 0;*/
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
</style>