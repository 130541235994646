import { render, staticRenderFns } from "./Curl.vue?vue&type=template&id=508eef55&scoped=true"
import script from "./Curl.vue?vue&type=script&lang=js"
export * from "./Curl.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "508eef55",
  null
  
)

export default component.exports