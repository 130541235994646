<template>
  <div>
    <div>
      <a-back-top />
    </div>
    <div class="home">
      <div class="hero">
        <h1 class="title">让全世界顶级交易员为你打工</h1>
        <p class="subtitle">
          全自动合约跟单交易助手。没有交易员盈利抽成，没有跟单席位限制，支持跨交易所和模拟盘跟单。<br>对接交易所官方API，秒级跟单。
        </p>
        <div>
          <div class="ratings">
            <a-space :size="-14" class="avatar-chips" style="margin: 0 5px">
              <a-avatar v-for="(image, index) in userImages" :key="index" :src="image" class="user-image" alt="User"/>
            </a-space>
            <div class="stars">
              <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
              <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
              <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
              <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
              <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
            </div>
          </div>
          <p class="trusted text-gray-7">超过1000+交易员正在使用</p>
        </div>
        <router-link to="/dashboard" class="nav-link supported">
          <a-button type="primary" size="large" shape="round" class="demo-button">
            免费开启我的跟单
            <svg style="margin-left: 10px" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd"></path></svg>
          </a-button>
          <a-button size="large" shape="round" class="demo-button" type="primary" ghost >
            授权接入
            <img src="/images/binance.png" alt="币安" style="width: 20px; height: 20px;margin: 0 5px;">
            <img src="/images/okx.png" alt="欧意" style="width: 20px; height: 20px;margin: 0 5px;">
          </a-button>
        </router-link>
      </div>
    </div>

    <div class="app">
      <div class="background-image"></div>
      <div class="screenshot">
        <img src="/images/screenshot.png" alt="应用界面截图" />
      </div>
    </div>

    <div id="features" class="features-section">
      <h1>特色功能</h1>
      <p>你能想到的，只要是对交易有帮助的功能，我们都实现了。</p>
      <div class="features-container">
        <div class="feature-card">
          <div class="icon blue">
            <a-icon type="bank" theme="outlined" />
          </div>
          <h3>跟单主流交易所</h3>
          <p>凡是在交易所上有公开带单的交易员，都支持跟单</p>
        </div>
        <div class="feature-card">
          <div class="icon purple">
            <a-icon type="lock" theme="outlined" />
          </div>
          <h3>私域跟单</h3>
          <p>针对私域带单或者隐藏实盘的交易员进行跟单</p>
        </div>
        <div class="feature-card">
          <div class="icon green">
            <a-icon type="appstore" theme="outlined" />
          </div>
          <h3>跨平台跟单</h3>
          <p>可以使用OK账户跟单币安，也可以使用币安账户跟单OK</p>
        </div>
        <div class="feature-card">
          <div class="icon orange">
            <a-icon type="api" theme="outlined" />
          </div>
          <h3>API跟单/带单</h3>
          <p>可以自定义上传需要跟单的API，自己或者他人都可以跟随该API进行交易</p>
        </div>
        <div class="feature-card">
          <div class="icon red">
            <a-icon type="sync" theme="outlined" />
          </div>
          <h3>反向跟单</h3>
          <p>对跟单的交易员进行反向交易，交易员开多，你便开空</p>
        </div>
        <div class="feature-card">
          <div class="icon brown">
            <a-icon type="profile" theme="outlined" />
          </div>
          <h3>交易对黑白名单</h3>
          <p>自定义交易对黑白名单，自主把控交易品种</p>
        </div>
        <div class="feature-card">
          <div class="icon deeporange">
            <a-icon type="schedule" theme="outlined" />
          </div>
          <h3>盈利条件跟单</h3>
          <p>可设置交易员盈利条件，满足条件后自己才进行跟单交易</p>
        </div>
        <div class="feature-card">
          <div class="icon pink">
            <a-icon type="fire" theme="outlined" />
          </div>
          <h3>热门交易员跟单</h3>
          <p>全网搜寻并监控热门交易员，实时进行跟单</p>
        </div>
        <div class="feature-card">
          <div class="icon teal">
            <a-icon type="safety" theme="outlined" />
          </div>
          <h3>止盈止损</h3>
          <p>自定义交易的止盈止损，保证交易安全</p>
        </div>
        <div class="feature-card">
          <div class="icon lime">
            <a-icon type="thunderbolt" theme="outlined" />
          </div>
          <h3>极速交易</h3>
          <p>针对不同的交易所，智能切换交易服务器，1s内完成交易</p>
        </div>
      </div>
    </div>

    <div id="comments" class="comments">
      <h1>用户评价</h1>
      <div class="reviews-container">
        <div class="reviews">
          <div class="reviews-list">
            <!-- 原始评论列表 -->
            <div class="review-card" v-for="(review, index) in reviews" :key="index">
              <div class="review-header">
                <a-space :size="-12" class="avatar-chips">
                  <a-avatar src="/images/img-logo.png" style="background-color: #FFFFFF; margin-right: 10px;width: 36px;height: 36px;border: 1px solid rgba(239, 239, 239, 0.95);" />
                </a-space>
                <span class="username">{{ review.username }}</span>
              </div>
              <div class="review-body">
                <span class="comments-body">{{ review.text }}</span>
              </div>
            </div>
            <!-- 重复的评论列表，用于无缝滚动 -->
            <div class="review-card" v-for="(review, index) in reviews" :key="'duplicate-' + index">
              <div class="review-header">
                <a-space :size="-12" class="avatar-chips">
                  <a-avatar src="/images/img-logo.png" style="background-color: #FFFFFF; margin-right: 10px;width: 36px;height: 36px;border: 1px solid rgba(239, 239, 239, 0.95);" />
                </a-space>
                <span class="username">{{ review.username }}</span>
              </div>
              <div class="review-body">
                <span class="comments-body">{{ review.text }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="faq" class="faq">
      <h1>常见问题</h1>
      <div class="faq-container">
        <a-collapse :bordered="false" class="faq-list" accordion>
          <template #expandIcon="props">
            <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
          </template>
          <a-collapse-panel key="1" header="能否使用OK账户跟单币安的交易员？" class="customStyle">
            <p>我们支持使用OK跟单币安的交易员，也支持使用币安跟单OK的交易员。</p>
          </a-collapse-panel>
          <a-collapse-panel key="2" header="交易员隐藏了实盘是否可以跟单？" class="customStyle">
            <p>使用普通的跟单服务，在隐藏实盘的情况下无法跟单，比如OK的隐藏带单数据，以及币安的私域跟单，都是无法进行跟单的。此时可以使用我们的高级跟单服务，可以突破交易所隐藏限制，帮你实现跟单。</p>
          </a-collapse-panel>
          <a-collapse-panel key="3" header="使用跟单交易所的交易员时为什么会有延迟？" class="customStyle">
            <p>交易所的延迟是交易所系统设置的，比如OK有5分钟的开仓展示延迟。如果想突破延迟，实现实时跟单，我们也提供了高级的跟单服务。</p>
          </a-collapse-panel>
          <a-collapse-panel key="4" header="是否可以同时跟单多个交易员？" class="customStyle">
            <p>出于对交易稳定性的考虑，我们只允许一个交易所账户同时跟单一个交易员，如果你需要跟单多个交易员，可以在交易所申请子账号，将子账号的api授权给我们，这样就可以同时跟单多个交易员。</p>
          </a-collapse-panel>
          <a-collapse-panel key="5" header="如何才能提高资产金额限制，用更多的资金进行跟单？" class="customStyle">
            <p>出于对用户资产的保护，避免交易时因滑点导致过多的资金损失，我们限制了用户使用软件的资产上限。如果你需要更多的资金交易，请联系我们购买私有化部署服务，我们将为你部署交易服务到你自己的服务器上，你可以获得更低的交易延迟，同时我们也将免费为你远程维护服务！</p>
          </a-collapse-panel>
          <a-collapse-panel key="6" header="如何确保我的账户资金安全？" class="customStyle">
            <p>我们要求用户提交的api不要添加交易所资金权限，交易所可以很好的保护你的资金安全。同时我们也向用户提供了专属ip绑定到api上，在其他ip地址下无法通过api操作你的账户！</p>
          </a-collapse-panel>
          <a-collapse-panel key="7" header="使用跟单猿跟单盈利了是否有分润？" class="customStyle">
            <p>没有任何分润，你盈利了多少都是你的！</p>
          </a-collapse-panel>
        </a-collapse>
      </div>
    </div>

    <div id="contact" class="contact">
      <a-row type="flex" :gutter="[16,16]" justify="space-around" align="top" class="contact-row">
        <a-col :span="12" :md="12" :lg="12" :xl="12" class="left">
          <div class="contact-title">
            <h1 style="font-size: 36px;margin-bottom: 30px;">联系我们</h1>
            <div class="contact-info-container">
              <div class="contact-info">
                <svg t="1703669708439" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="879" width="25" height="25">
                  <path d="M512 1024A512 512 0 1 0 512 0a512 512 0 0 0 0 1024z" fill="#4493f7" p-id="880"></path>
                  <path d="M273.088 366.4s206.08 116.864 246.016 116.864c41.92 0 245.76-114.624 245.76-114.624l0.384-20.48a40.96 40.96 0 0 0-41.024-40.96H314.048a40.96 40.96 0 0 0-40.96 40.96v18.24z m491.776 63.68s-201.856 109.504-245.76 109.504c-40 0-245.76-109.44-245.76-109.504l-0.256 245.76c0 22.592 18.368 40.96 40.96 40.96h410.176a40.96 40.96 0 0 0 40.96-40.96l-0.32-245.76z" fill="#ffffff" p-id="881"></path>
                </svg>
                <span>service@copyapes.com</span>
              </div>
              <div class="contact-info">
                <svg t="1703669911211" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="879" width="25" height="25">
                  <path d="M1024 512c0 281.6-230.4 512-512 512S0 793.6 0 512 230.4 0 512 0s512 230.4 512 512z" fill="#4493f7" p-id="880"></path>
                  <path d="M697.14 767.18l100.556-489.062c10.07-41.199-15.087-61.78-45.26-51.507l-593.58 231.663c-40.277 15.463-40.277 41.2-5.051 51.473l150.937 51.507 357.172-231.663c15.087-10.308 30.173-5.154 20.138 5.12L395.264 602.453l-10.07 159.608c15.088 0 25.157-5.154 30.175-15.463l75.468-72.055L646.793 792.95c25.156 15.429 45.26 5.12 50.312-25.736z" fill="#ffffff" p-id="881"></path>
                </svg>
                <span>社区：<a href="https://t.me/copyapes_cn" target="_blank">https://t.me/copyapes_cn</a></span>
              </div>
              <div class="contact-info">
                <svg t="1703669911211" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="879" width="25" height="25">
                  <path d="M1024 512c0 281.6-230.4 512-512 512S0 793.6 0 512 230.4 0 512 0s512 230.4 512 512z" fill="#4493f7" p-id="880"></path>
                  <path d="M697.14 767.18l100.556-489.062c10.07-41.199-15.087-61.78-45.26-51.507l-593.58 231.663c-40.277 15.463-40.277 41.2-5.051 51.473l150.937 51.507 357.172-231.663c15.087-10.308 30.173-5.154 20.138 5.12L395.264 602.453l-10.07 159.608c15.088 0 25.157-5.154 30.175-15.463l75.468-72.055L646.793 792.95c25.156 15.429 45.26 5.12 50.312-25.736z" fill="#ffffff" p-id="881"></path>
                </svg>
                <span>客服：<a href="https://t.me/copyapes_admin" target="_blank">@copyapes_admin</a></span>
              </div>
            </div>
          </div>
          <img src="/images/world.svg" alt="world map">
        </a-col>
        <a-col :span="12" :md="12" :lg="12" :xl="12" class="right">
          <div class="form-container">
            <a-form
                :form="form"
                :hideRequiredMark="true"
                @submit.prevent="onSubmit"
            >
              <a-form-item :colon="false" label="如何称呼你" :validateStatus="getFieldError('full_name') ? 'error' : ''" :help="getFieldError('full_name')">
                <a-input
                    placeholder="你的称呼"
                    v-decorator="[
                      'full_name',
                      {
                        rules: [{ required: true, message: '称呼是必须的' }],
                      },
                    ]"
                />
              </a-form-item>
              <a-form-item :colon="false" label="你的联系方式" :validateStatus="getFieldError('connect') ? 'error' : ''" :help="getFieldError('connect')">
                <a-input
                    placeholder="微信、QQ、邮箱、手机均可"
                    v-decorator="[
                    'connect',
                    {
                      rules: [{ required: true, message: '联系方式是必须的' }],
                    },
                  ]"
                />
              </a-form-item>
              <a-form-item :colon="false" label="留言信息" :validateStatus="getFieldError('message') ? 'error' : ''" :help="getFieldError('message')">
                <a-textarea
                    placeholder="在这里写下你的留言，我们将第一时间联系你！欢迎媒体、KOL、交易员、私域群主等等前来洽谈合作！"
                    :auto-size="{ minRows: 4, maxRows: 8 }"
                    v-decorator="[
                    'message',
                    {
                      rules: [{ required: true, message: '留言是必须的' }],
                    },
                  ]"
                />
              </a-form-item>
              <a-form-item>
                <a-button type="primary" html-type="submit" :loading="loading">
                  勾搭一下
                </a-button>
              </a-form-item>
            </a-form>
          </div>
        </a-col>
      </a-row>
    </div>

  </div>
</template>

<script>
import http from "../utils/http"

export default {
  name: "Index",
  data() {
    return {
      userImages: [
        '/images/face-1.jpg',
        '/images/face-2.png',
        '/images/face-3.png',
        '/images/face-4.png',
        '/images/face-5.png',
        '/images/face-6.png',
        '/images/face-7.png',
      ],

      // 用户评价列表
      reviews: [
        { username: "匿名OK用户", text: "可能是市面最好用的跟单工具，性价比极高，网站也很简洁。" },
        { username: "匿名币安用户", text: "用了五个月，一直在用，也没用过其他的软件。非常稳定，开发者更新频率也非常高。" },
        { username: "匿名币安用户", text: "朋友介绍用的，就是为了可以跟单浪浪，跟单浪浪在上半年的时候反了不少倍，希望跟单猿越来越牛逼。" },
        { username: "匿名OK用户", text: "一直在找可以复制别人交易仓位的软件，通过搜索找到的，让我眼前一亮，感谢开发者开发了这么牛逼工具。" },
        { username: "匿名OK用户", text: "我有自己的交易社群，用了跟单猿之后，我尝试选择和跟单猿合作推广。我社群里的用户也都非常喜欢这个工具，真的是什么都能跟。" },
      ],
      translateX: 0,
      interval: null,

      // 联系信息
      loading: false,
      form: this.$form.createForm(this), // 使用 Ant Design 的表单
      full_name: '',
      connect: '',
      message: '',
    };
  },
  methods: {
    onSubmit() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.loading = true;
          // 这里处理表单提交逻辑，例如发送请求
          // console.log('Submitted values:', values);
          await http.post('/api/sendmessage/', values).then(response => {
            // 处理响应数据
            if (response.data.code === 0) {
              this.$message.success('勾搭成功！');
            } else {
              this.$message.error('发送失败，请联系微信：copyapes_admin');
            }
            console.log(response.data);
          }).catch(error => {
            // 处理错误
            this.$message.error('发送失败，请联系微信：copyapes_admin');
            console.error(error);
          });
          // 提交后重置 loading 状态
          this.loading = false;
          this.resetForm()
        }
      });
    },
    resetForm() {
      this.form.resetFields();
    },
    getFieldError(field) {
      const errors = this.form.getFieldError(field);
      return errors ? errors[0] : '';
    },
  },
}
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  /*height: calc(100vh - 40px); !* 减去内边距的高度 *!*/
  height: 85vh;
  /*background: #FFFFFF;*/
  color: #000; /* 字体颜色 */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 60px;
  @media (max-width: 480px) {
    margin-top: 10px;
  }
  @media (min-width: 481px) and (max-width: 767px) {
    margin-top: 30px;
  }
  @media (min-width: 768px){
    height: 70vh;
    padding-top: 40px;
    margin-bottom: 150px;
  }
  @media (min-width: 1500px) {
    height: 65vh;
    padding-top: 65px;
    margin-bottom: 165px;
  }
}

.hero {
  max-width: 900px; /* 限制最大宽度 */
  @media (min-width: 1500px) {
    max-width: 1200px;
  }
}

.title {
  color: #4493f7;
  font-size: 6rem;
  line-height: 1.2;
  margin: 0 20px;
  @media (max-width: 480px) {
    font-size: 3rem;
    margin: 0 20px;
  }
  @media (min-width: 481px) and (max-width: 767px) {
    font-size: 4rem;
    margin: 0 20px;
  }
  @media (min-width: 768px) {
    font-size: 6rem;
    margin: 0 20px;
  }
  @media (min-width: 1500px) {
    font-size: 8rem;
    margin: 0 20px;
  }
}

.subtitle {
  font-size: 18px; /* 副标题字体大小 */
  margin: 30px 0;
  @media (max-width: 480px) {
    font-size: 14px;
    margin: 20px;
  }
  @media (min-width: 481px) and (max-width: 767px) {
    font-size: 16px;
    margin: 20px;
  }
  @media (min-width: 1500px) {
    font-size: 22px;
  }
}

.ratings {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
}

.user-image {
  width: 50px; /* 用户头像宽度 */
  height: 50px; /* 用户头像高度 */
  @media (max-width: 576px) {
    width: 30px;
    height: 30px;
  }
}

.stars {
  color: rgb(250,204,21);
  svg {
    width: 1.2rem;
    height: 1.2rem;
    margin-left: 2px;
    margin-right: 2px;
  }
}

.trusted {
  margin-top: -25px; /* 信任文字的左边距 */
}

.demo-button {
  margin: 0 10px;
  height: 58px;
  img {
    width: 20px;
    height: 20px;
    margin-left: 5px;
    margin-right: 5px;
  }
  @media (max-width: 576px) {
    margin: 0 5px;
    height: 46px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 14px;
  }
}

.supported {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.app {
  margin-top: 80px;
  position: relative;
  /*height: 100vh; !* 填满整个视口高度 *!*/
  @media (max-width: 576px) {
    margin-top: 0;
  }
}

.app .background-image {
  background-image: url('/images/bg-home.png');
  background-size: cover; /* 使背景图保持原始比例，并完整显示 */
  background-repeat: no-repeat;
  background-position-x: 50%; /* 使背景图居中显示 */
  background-position-y: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* 宽度为 100% */
  height: auto; /* 高度自适应 */
  aspect-ratio: 4320 / 1839; /* 根据图片的实际宽高比调整 */
  z-index: 1; /* 使背景位于底层 */
}

.screenshot {
  position: relative;
  z-index: 2; /* 使截图位于前层 */
  display: flex;
  justify-content: center; /* 居中对齐 */
  align-items: center; /* 垂直居中 */
  height: 100%; /* 充满父容器 */
  top: -100px
}

.screenshot img {
  max-width: 70%; /* 自适应宽度 */
  height: auto; /* 自适应高度 */
  border: 1px solid #f6f6f6; /* 描边颜色和宽度 */
  border-radius: 15px; /* 圆角大小 */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* 可选的阴影效果 */
  @media (max-width: 576px) {
    max-width: 90%;
  }
}

.features-section {
  text-align: center;
  padding: 40px 20px; /* 上下内边距 */
  margin: 60px;
  @media (max-width: 576px) {
    margin: 0;
    padding: 20px;
  }
}

.features-section h1 {
  font-size: 36px; /* 主标题字体大小 */
  margin-bottom: 10px;
  @media (max-width: 576px) {
    font-size: 28px;
  }
}

.features-section p {
  font-size: 18px; /* 描述字体大小 */
  margin-bottom: 50px; /* 描述和功能卡片之间的间距 */
  @media (max-width: 576px) {
    font-size: 16px;
  }
}

.features-container {
  display: flex; /* 使用 Flexbox 布局 */
  flex-wrap: wrap; /* 自动换行 */
  justify-content: center; /* 居中对齐 */
  gap: 25px; /* 卡片之间的间距 */
}

.feature-card {
  display: flex; /* 使用 Flexbox 布局 */
  flex-direction: column; /* 垂直方向布局 */
  justify-content: center; /* 垂直居中 */
  align-items: center; /* 水平居中 */
  background-color: white; /* 卡片背景颜色 */
  border-radius: 10px; /* 圆角 */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* 添加阴影效果 */
  padding: 20px; /* 内边距 */
  text-align: center; /* 内容居中 */
  width: 220px; /* 固定宽度 */

  h3 {
    margin-top: 10px;
    font-size: 20px;
  }

  p {
    /*text-align: left;*/
    font-size: 14px;
  }

  @media (max-width: 576px) {
    width: 150px;
    padding: 10px;
    h3 {
      font-size: 16px;
    }
    p {
      font-size: 12px;
    }
  }
}

.icon {
  display: flex; /* 使用 Flexbox 布局 */
  justify-content: center; /* 垂直居中 */
  align-items: center; /* 水平居中 */
  color: #FFFFFF;
  font-size: 30px;
  width: 60px; /* 图标宽度 */
  height: 60px; /* 图标高度 */
  margin: 10px auto; /* 下边距 */
  @media (max-width: 576px) {
    width: 40px;
    height: 40px;
    font-size: 20px;
  }
}

.blue { background-color: #4f93f8; }
.purple { background-color: #734bf3; }
.green { background-color: #67cb4b; }
.orange { background-color: #f0b345; }
.deeporange { background-color: #ff5722; }
.brown { background-color: #795548; }
.red { background-color: #ec5048; }
.teal { background-color: #00796b; }
.pink { background-color: #ff4081; }
.lime { background-color: #cddc39; }


.comments {
  display: flex; /* 使用 Flexbox 布局 */
  flex-direction: column; /* 垂直方向布局 */
  justify-content: center; /* 垂直居中 */
  align-items: center; /* 左对齐 */
  position: relative;
  overflow: hidden; /* 隐藏溢出的内容 */
  background: linear-gradient(180deg, #e6f0f9, #fff);
  height: 500px;
  h1 {
    font-size: 36px;
    margin-bottom: 40px;
  }
  @media (max-width: 576px) {
    height: 400px;
    h1 {
      font-size: 28px;
      margin-bottom: 30px;
    }
  }
}

.reviews-container {
  position: relative;
  z-index: 2; /* 确保评论在背景之上 */
  width: 100%;
  height: 300px; /* 根据需要调整高度 */
  overflow: hidden; /* 隐藏溢出的内容 */
  @media (max-width: 576px) {
    height: 250px;
  }
}

.reviews {
  display: flex; /* 使用 Flexbox 布局 */
  /*white-space: nowrap; !* 不换行 *!*/
  transition: transform 0.5s linear; /* 平滑过渡效果 */
}

.reviews-list {
  display: flex;
  width: 200%; /* 内容宽度为容器的两倍 */
  animation: scroll-left 20s linear infinite;
}

.review-card {
  display: flex; /* 使用 Flexbox 布局 */
  flex-direction: column; /* 垂直方向布局 */
  justify-content: center; /* 垂直居中 */
  align-items: flex-start; /* 左对齐 */
  background-color: rgba(255, 255, 255, 0.8); /* 半透明的白色背景 */
  border-radius: 10px; /* 圆角 */
  /*box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); !* 阴影效果 *!*/
  padding: 20px; /* 内边距 */
  margin-right: 20px; /* 卡片之间的间距 */
  width: 250px; /* 每个卡片的固定宽度 */
  height: 300px; /* 高度自动调整 */
  overflow: hidden; /* 隐藏溢出的内容 */
  box-sizing: border-box; /* 包含内边距和边框在内的宽度 */
  flex-shrink: 0; /* 防止卡片被压缩 */
  @media (max-width: 576px) {
    border-radius: 5px;
    width: 200px;
    height: 250px;
  }
}

.review-header {
  display: flex; /* 使用 Flexbox 布局 */
  justify-content: space-between; /* 在左右之间分布 */
  align-items: center;
  margin-bottom: 10px; /* 下边距 */
}
.review-body {
  display: flex;
  justify-content: left;
  margin-top: 10px; /* 增加上边距以分隔标题 */
  width: 100%; /* 确保文本区域的宽度 */
  height: 300px;
}

.username {
  font-weight: bold; /* 粗体 */
}

.rating {
  font-size: 14px; /* 字体大小 */
  color: #999; /* 颜色 */
}

.comments-body {
  font-size: 16px; /* 描述字体大小 */
  color: #333; /* 描述文字颜色 */
  width: 100%; /* 确保文本区域的宽度 */
  box-sizing: border-box; /* 包含内边距和边框在内的宽度 */
  word-wrap: break-word; /* 允许长单词在边界内换行 */
  overflow-wrap: break-word; /* 兼容性属性，确保文本不会溢出 */
  /*text-align: left; !* 左对齐文本 *!*/
}

/* 定义滚动动画 */
@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%); /* 向左移动50%，即一半的内容宽度 */
  }
}

/* 鼠标悬停时暂停动画 */
.reviews-container:hover .reviews-list {
  animation-play-state: paused;
}


.faq {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
  h1 {
    font-size: 36px;
    margin-bottom: 40px;
  }
  @media (max-width: 576px) {
    margin: 10px 0;
    h1 {
      font-size: 28px;
      margin-bottom: 30px;
    }
  }
}
.faq-container {
  width: 800px;
  @media (max-width: 576px) {
    width: 90%;
  }
}
.faq-list {
  background-color: #FFFFFF;
  .ant-collapse-header {
    font-size: 18px;
    font-weight: 600;
    height: 56px;
    padding: 16px;
    padding-left: 40px;
  }
  p {
    line-height: 1.8;
  }
}
.customStyle {
  background: #fff;
  border-radius: 5px!important;
  margin-bottom: 12px;
  border: 1px solid rgba(239, 239, 239, 0.95);
  overflow: hidden;
}


.contact {
  margin: 100px 0;
  padding: 0 20px;
  @media (max-width: 576px) {
    margin: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center
  }
}
.contact-row {
  @media (max-width: 576px) {
    flex-direction: column;
    align-items: center;
    justify-content: center
  }
}
.contact .left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 500px;
    height: auto;
    transform: rotateX(45deg) translateZ(0);
  }
  @media (max-width: 576px) {
    img {
      width: 300px;
    }
  }
}
.contact-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 576px) {
    align-items: center;
  }
}
.contact .left .icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.contact-info-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
  @media (min-width: 1500px) {
    font-size: 20px;
  }
}
.contact-info {
  display: flex;
  justify-content: center;
  align-items: center;
}
.right{
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-container{
  padding: 30px;
  background-color: #f1f2f4;
  border-radius: 20px;
  width: 500px;
  height: auto;

  @media (max-width: 576px) {
    border-radius: 10px;
    width: 600px;
  }
  @media (min-width: 1500px) {
    width: 700px;
  }
}
</style>
<style lang="scss">
.faq-container{
  .ant-collapse {
    @media (min-width: 1500px) {
      font-size: 18px;
    }
  }
  p {
    @media (min-width: 1500px) {
      font-size: 18px;
    }
  }
}
.form-container{
  .ant-form label {
    font-size: 16px;
    font-weight: 600;
    @media (min-width: 1500px) {
      font-size: 18px;
    }
  }
}
</style>