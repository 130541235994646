<template>
  <div>
    <a-card :bordered="false" class="card-product header-solid mb-24">
      <template #title>
        <h5>币coin跟单</h5>
      </template>

      <h6>
        <span style="color: #EC575BFF;margin-right: 4px">*</span>
        关联币coin账号
        <span style="color: #EC575BFF;font-weight: lighter;margin-left: 4px;font-style: italic;">(必填)</span>
      </h6>
      <a-row type="flex" :gutter="[24]" justify="start">
        <a-col :span="6">
          <a-form-item class="mb-10" label="手机号" :colon="false">
            <a-input v-model:value="phone" :disabled="!isEditable" placeholder="手机号"/>
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item class="mb-10" label="密码" :colon="false">
            <a-input-password v-model:value="password" :disabled="!isEditable" placeholder="密码"/>
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item class="mb-10" label=" " :colon="false">
            <a-button :loading="isLoading" type="primary" block @click="handleButtonClick">{{ buttonText }}</a-button>
          </a-form-item>
        </a-col>
      </a-row>
      <div>
        <span class="text-gray-5" style="font-weight: 400; font-size: 12px">
          <a-icon type="warning" theme="outlined"/>
              如需跟单隐藏实盘的交易员，请先获取他的订阅码，否则无法跟单！
        </span>
      </div>
      <div style="margin-top: 50px">
        <h6>
          <span style="color: #EC575BFF;margin-right: 4px">*</span>
          选择交易员
        </h6>
        <div style="margin-top: 25px">
          <a-input-search
              placeholder="输入交易员昵称"
              enter-button="搜索"
              @search="onSearch"
              style="width: 40%"
          />
        </div>
        <a-table
            :columns="columns"
            :data-source="leader_list"
            :row-selection="rowSelection"
            :pagination="false"
            style="width: 75%;margin-top: 15px;border: 1px solid #ddd;border-radius: 8px;"
        >

          <template slot="leader" slot-scope="leader">
            <div class="table-avatar-info">
              <a-avatar shape="square" :src="leader.img" />
              <div class="avatar-info">
                <h6>{{ leader.leaderName }}</h6>
                <p>{{ leader.slogen }}</p>
              </div>
            </div>
          </template>

          <template slot="api" slot-scope="api">
            <div class="author-info">
              <h6 class="m-0" style="color: #31BD65FF">${{ api.balance }}</h6>
              <p class="m-0 font-regular text-muted" style="display: flex;align-items: center;">
                <img :src="api.exchImage" style="width: 15px;height: 15px;margin-right: 5px" />
                {{ api.exchange }}
              </p>
            </div>
          </template>

          <template slot="info" slot-scope="info">
            <a-tag class="tag-status" :class="info.status===1 ? 'ant-tag-primary' : 'ant-tag-muted'">
              {{ info.statusStr }}
            </a-tag>
          </template>

        </a-table>
      </div>
      <div style="margin-top: 50px">
        <h6>
          <span style="color: #EC575BFF;margin-right: 4px">*</span>
          跟单设置
        </h6>
        <a-form
            :form="form"
            :hideRequiredMark="true"
            style="width: 70%"
        >
          <a-row :gutter="[24]" type="flex" justify="start">
            <a-col :span="14">
              <a-form-item class="mb-10" label="跟单数据源" :colon="false">
                <a-radio-group v-decorator="['role_type', { initialValue: '1' }]" button-style="solid">
                  <a-radio-button value="1">操作记录</a-radio-button>
                </a-radio-group>
              </a-form-item>
<!--              <span class="text-gray-6" style="font-weight: 400; font-size: 12px">-->
<!--                    交易员类型说明-->
<!--              </span>-->
<!--              <br/>-->
<!--              <span class="text-gray-6" style="font-weight: 400; font-size: 12px">-->
<!--                    带单员：可以在平台直接跟单的交易员-->
<!--              </span>-->
<!--              <br/>-->
<!--              <span class="text-gray-6" style="font-weight: 400; font-size: 12px">-->
<!--                    普通用户：可以看到个人交易页面，但是无法在平台直接跟单的交易员-->
<!--              </span>-->
<!--              <br/>-->
<!--              <span class="text-gray-6" style="font-weight: 400; font-size: 12px">-->
<!--                <a-icon type="warning" theme="outlined"/>-->
<!--                    请注意，只支持没有隐藏实盘仓位的交易员，错误选择交易员将导致无法跟单！-->
<!--              </span>-->
            </a-col>
            <a-col :span="14">
              <a-form-item class="mb-10" label="选择你的跟单API" :colon="false">
                <a-select v-decorator="[
                      'id',
                      {
                        rules: [{ required: true, message: '选择你的跟单API' }]
                      }
                    ]">
                  <a-select-option v-for="option in apiOptions" :key="option.id" :id="option.id">
                    {{ option.api_name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="14">
              <a-form-item class="mb-10" label="跟单模式" :colon="false">
                <a-radio-group button-style="solid" v-decorator="['follow_type', { initialValue: '2' }]">
                  <a-radio-button value="2">智能跟单</a-radio-button>
                </a-radio-group>
              </a-form-item>
            </a-col>

            <a-col :span="14" style="margin-bottom:10px;margin-top:5px;background-color: #e9f7fe;padding: 10px;border-radius: 5px">
              <a-row type="flex" align="middle">
                <a-col :span="24" :md="12" class="col-info">
                  <div class="avatar-info">
                    <p class="font-semibold m-0" style="font-size: 13px;font-weight: 600;color: #141414;">倍投模式 (可选)</p>
                  </div>
                </a-col>
                <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
                  <small class="mr-5" v-if="multiple_visible === false">关闭</small>
                  <small class="mr-5" v-if="multiple_visible === true">开启</small>
                  <a-switch default-checked v-model="multiple_visible" @change="onChangeMode"/>
                </a-col>
              </a-row>
              <a-form-item class="mb-10" label="加倍倍数(默认为1)" :colon="false" v-if="multiple_visible">
                <a-input placeholder="eg. 2（在原有开单保证金的基础上 * 2，多承担一倍爆仓风险）"
                         v-decorator="['multiple',{ rules: [
                             { required: true, message: '请输入你要加倍投注的倍数！' },
                             { min: 1, message: '长度不能少于1位！' },
                             { validator: (rule, value, callback) => {
                                const numberValue = parseFloat(value);
                                if (isNaN(numberValue) || !isFinite(numberValue)) {
                                  callback('请输入有效的数字！');
                                } else if (numberValue < 1) {
                                  callback('输入数值必须大于等于1');
                                } else if (!/^\d+(\.\d+)?$/.test(value)) {
                                  callback('请输入标准的数字格式，不要包含非数字字符');
                                }else {
                                  callback();
                                }
                              },
                              // message: '输入数值必须大于5',
                              trigger: 'change'
                            },
                          ]
                         },]"/>
              </a-form-item>
              <div v-if="multiple_visible">
                <span class="text-primary" style="font-weight: 400; font-size: 12px">
                    【加倍模式说明】
                </span>
                <br/>
                <span class="text-primary" style="font-weight: 400; font-size: 12px">
                      只适用于经常小金额高杠杆交易的交易员。
                </span>
                <br/>
                <span style="font-weight: 400; font-size: 12px; color: red">
                      如果跟单的交易员经常满仓交易，请不要使用此功能！
                </span>
              </div>
            </a-col>

            <a-col :span="14">
              <a-form-item class="mb-10" label="投资额（将作为计算每次开单的金额基数）" :colon="false">
                <a-input placeholder=">100 注意：金额过低可能会无法开单" prefix="$" suffix="USDT"
                         v-decorator="['investment',{ rules: [
                             { required: true, message: '请输入你的投资额！' },
                             { min: 1, message: '长度不能少于1位！' },
                             { validator: (rule, value, callback) => {
                                const numberValue = parseFloat(value);
                                if (isNaN(numberValue) || !isFinite(numberValue)) {
                                  callback('请输入有效的数字！');
                                } else if (numberValue < 100) {
                                  callback('输入数值必须大于100');
                                } else {
                                  callback();
                                }
                              },
                              trigger: 'change'
                            },
                          ]
                         },]"/>
              </a-form-item>
              <span class="text-primary" style="font-weight: 400; font-size: 12px">
                    【智能跟单说明】
              </span>
              <br/>
              <span class="text-primary" style="font-weight: 400; font-size: 12px">
                    实时计算你的投资额和交易员实际本金的比例，按该比例进行下单。
              </span>
              <br/>
              <span class="text-primary" style="font-weight: 400; font-size: 12px">
                    如果一个API同时跟单多名交易员，建议累计投资额不要超过实际账户本金，否则会有很大爆仓风险。
              </span>
            </a-col>
            <a-col :span="14">
              <a-form-item class="mb-10" label="杠杆设置" :colon="false">
                <a-radio-group button-style="solid" style="margin-right: 10px" v-decorator="['lever_set', { initialValue: '2' }]">
                  <a-radio-button value="2">自定义杠杆</a-radio-button>
                </a-radio-group>
                <a-input placeholder="1至50之间任意整数" style="width: 248px"
                         v-decorator="['leverage',{ rules: [
                             { validator: (rule, value, callback) => {
                                const numberValue = parseFloat(value);
                                if (isNaN(numberValue) || !isFinite(numberValue)) {
                                  callback('请输入有效的数字！');
                                } else if (numberValue < 1) {
                                  callback('输入数值必须大于1');
                                } else if (numberValue > 50) {
                                  callback('输入数值必须小于51');
                                } else if (!/^\d+(\.\d+)?$/.test(value)) {
                                  callback('请输入标准的数字格式，不要包含非数字字符');
                                }else {
                                  callback();
                                }
                              },
                              trigger: 'change'
                            },
                          ]
                         },]"/>
              </a-form-item>
              <div v-if="form.getFieldValue('lever_set') === '2'">
                <span class="text-primary" style="font-weight: 400; font-size: 12px">
                  【自定义杠杆说明】
                </span>
                <br/>
                <span class="text-primary" style="font-weight: 400; font-size: 12px">
                  假设自定义杠杆为20，即代表可以开出价值20倍本金的持仓。
                </span>
                <br/>
                <span class="text-primary" style="font-weight: 400; font-size: 12px">
                  相反，若设置下单杠杆为3，此时当交易员的净杠杆(即持仓价值与本金的比值)超过3倍，那您的本金即以全部作为保证金而无法再加仓或开新的仓位。
                </span>
              </div>
            </a-col>
            <a-col :span="14">
              <a-form-item class="mb-10" label="开仓模式（交易员每新开一个币种合约时生效）" :colon="false">
                <a-radio-group button-style="solid" v-decorator="['first_open_type', { initialValue: '2' }]">
                  <a-radio-button value="1">当前市价</a-radio-button>
                  <a-radio-button value="2">区间委托</a-radio-button>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <a-col :span="14" v-if="form.getFieldValue('first_open_type') === '2'">
              <a-form-item class="mb-10" label="交易员收益区间" :colon="false">
                <a-input placeholder="5（交易员收益率小于5%时开仓），-5（亏损大于5%时开仓）" prefix=" < " suffix="%"
                         v-decorator="['uplRatio',{ rules: [
                     { required: true, message: '请输入你的止盈百分比！' },
                     { validator: (rule, value, callback) => {
                        const numberValue = parseFloat(value);
                        if (isNaN(numberValue) || !isFinite(numberValue)) {
                          callback('请输入有效的数字！');
                        } else if (!/^-?\d+(\.\d+)?$/.test(value)) {
                          callback('请输入标准的数字格式，不要包含非数字字符');
                        } else {
                          callback();
                        }
                      },
                      trigger: 'change'
                    },
                  ]
                 },]"/>
              </a-form-item>
              <span class="text-primary" style="font-weight: 400; font-size: 12px">
                    【开仓模式说明】
              </span>
              <br />
              <span class="text-primary" style="font-weight: 400; font-size: 12px">
                      区间限价开仓只在交易员新开仓时生效，加仓时不生效。该模式在其他交易所也可使用。
              </span>
              <br />
              <span class="text-primary" style="font-weight: 400; font-size: 12px">
                      系统会自动监控最合适的进场点位第一时间开仓。
              </span>
            </a-col>
            <a-col :span="14">
              <a-form-item class="mb-10" label="首单跟单设置" :colon="false">
                <a-radio-group button-style="solid" v-decorator="['first_order_set', { initialValue: '1' }]">
                  <a-radio-button value="1">有新的加仓，立即跟单</a-radio-button>
                </a-radio-group>
              </a-form-item>
            </a-col>

            <!-- 更多高级配置 -->
            <a-col :span="14" style="margin-top: 10px">
              <a class="text-dark" @click="toggleShowLess('wechat')" style="font-weight: 600">
                高级设置
                <a-icon :type="showLess ? 'up' : 'down'" class="ml-5" />
              </a>
            </a-col>

            <a-col :span="14" v-if="showLess">
              <a-row type="flex" align="middle" style="margin-top: 20px;">
                <a-col :span="24" :md="12" class="col-info">
                  <div class="avatar-info">
                    <p class="font-semibold m-0" style="font-size: 14px;font-weight: 600;color: #141414;">🔁 反向跟单 ( 娱乐功能 )</p>
                  </div>
                </a-col>
                <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
                  <small class="mr-5" v-if="posSide_set_visible === false">关闭</small>
                  <small class="mr-5" v-if="posSide_set_visible === true">开启</small>
                  <a-switch default-checked v-model="posSide_set_visible" @change="onChangeMode"/>
                </a-col>
              </a-row>

              <a-row type="flex" align="middle" style="margin-top: 20px;">
                <a-col :span="24" :md="12" class="col-info">
                  <div class="avatar-info">
                    <p class="font-semibold m-0" style="font-size: 14px;font-weight: 600;color: #141414;">🚀 极速跟单 ( 限时免费 )</p>
                  </div>
                </a-col>
                <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
                  <small class="mr-5" v-if="fast_mode_visible === false">关闭</small>
                  <small class="mr-5" v-if="fast_mode_visible === true">开启</small>
                  <a-switch default-checked v-model="fast_mode_visible" @change="onChangeMode"/>
                </a-col>
              </a-row>


              <a-row type="flex" align="middle" style="margin-top: 20px;">
                <a-col :span="24" :md="12" class="col-info">
                  <div class="avatar-info">
                    <p class="font-semibold m-0" style="font-size: 14px;font-weight: 600;color: #141414;">⚠️ 交易止盈止损</p>
                  </div>
                </a-col>
                <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
                  <small class="mr-5" v-if="trade_trigger_visible === false">关闭</small>
                  <small class="mr-5" v-if="trade_trigger_visible === true">开启</small>
                  <a-switch default-checked v-model="trade_trigger_visible" @change="onChangeMode"/>
                </a-col>
              </a-row>
              <a-form-item class="mb-10" label="止盈百分比" :colon="false" v-if="trade_trigger_visible">
                <a-input placeholder="eg. 20（每笔交易盈利20%时止盈），0为不设置止盈" suffix="%"
                         v-decorator="['tp_trigger_px',{ rules: [
                             { required: true, message: '请输入你的止盈百分比！' },
                             { validator: (rule, value, callback) => {
                                const numberValue = parseFloat(value);
                                if (isNaN(numberValue) || !isFinite(numberValue)) {
                                  callback('请输入有效的数字！');
                                } else if (numberValue < 0) {
                                  callback('输入数值必须大于0');
                                }  else if (!/^\d+(\.\d+)?$/.test(value)) {
                                  callback('请输入标准的数字格式，不要包含非数字字符');
                                }else {
                                  callback();
                                }
                              },
                              trigger: 'change'
                            },
                          ]
                         },]"/>
              </a-form-item>
              <a-form-item class="mb-10" label="止损百分比" :colon="false" v-if="trade_trigger_visible">
                <a-input placeholder="eg. 20（每笔交易亏损20%时止损），0为不设置止损" suffix="%"
                         v-decorator="['sl_trigger_px',{ rules: [
                             { required: true, message: '请输入你的止损百分比！' },
                             { validator: (rule, value, callback) => {
                                const numberValue = parseFloat(value);
                                if (isNaN(numberValue) || !isFinite(numberValue)) {
                                  callback('请输入有效的数字！');
                                } else if (numberValue < 0) {
                                  callback('输入数值必须大于0');
                                }  else if (!/^\d+(\.\d+)?$/.test(value)) {
                                  callback('请输入标准的数字格式，不要包含非数字字符');
                                }else {
                                  callback();
                                }
                              },
                              trigger: 'change'
                            },
                          ]
                         },]"/>
              </a-form-item>

              <a-row type="flex" align="middle" style="margin-top: 20px;">
                <a-col :span="24" :md="12" class="col-info">
                  <div class="avatar-info">
                    <p class="font-semibold m-0" style="font-size: 14px;font-weight: 600;color: #141414;">⚠️ 多空开仓策略</p>
                  </div>
                </a-col>
                <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
                  <small class="mr-5" v-if="pos_visible === false">关闭</small>
                  <small class="mr-5" v-if="pos_visible === true">开启</small>
                  <a-switch v-model="pos_visible" @change="onChangeMode"/>
                </a-col>
                <a-col :span="14" v-if="pos_visible">
                  <a-form-item class="mb-10" label="多空开仓策略设置" :colon="false">
                    <a-radio-group v-decorator="['pos_value', { initialValue: 'long' }]" button-style="solid">
                      <a-radio-button value="long">
                        只跟多单
                      </a-radio-button>
                      <a-radio-button value="short">
                        只跟空单
                      </a-radio-button>
                    </a-radio-group>
                  </a-form-item>
                </a-col>
              </a-row>

              <a-row type="flex" align="middle" style="margin-top: 20px;">
                <a-col :span="24" :md="12" class="col-info">
                  <div class="avatar-info">
                    <p class="font-semibold m-0" style="font-size: 14px;font-weight: 600;color: #141414;">💹 24h成交量排行榜跟单</p>
                  </div>
                </a-col>
                <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
                  <small class="mr-5" v-if="vol24h_visible === false">关闭</small>
                  <small class="mr-5" v-if="vol24h_visible === true">开启</small>
                  <a-switch v-model="vol24h_visible" @change="onChangeMode"/>
                </a-col>
                <a-col :span="14" v-if="vol24h_visible">
                  <a-form-item class="mb-10" label="榜单排名" :colon="false">
                    <a-input placeholder="eg. 20（只跟排行榜前20的币种）"
                             v-decorator="['vol24h_num',{ rules: [
                               { required: true, message: '排行榜前几？' },
                               { validator: (rule, value, callback) => {
                                  // 检查是否是正整数并在 0 到 50 之间
                                  const isPositiveInteger = /^\d+$/.test(value);
                                  const numberValue = parseInt(value, 10);
                                  if (!isPositiveInteger || numberValue <= 0) {
                                    callback('请输入大于 0 的整数！');
                                  } else if (numberValue >= 50) {
                                    callback('请输入小于 50 的整数！');
                                  } else {
                                    callback();
                                  }
                                },
                                trigger: 'change'
                              },
                            ]
                           },]"/>
                  </a-form-item>
                </a-col>
              </a-row>

              <a-row type="flex" align="middle" style="margin-top: 20px;">
                <a-col :span="24" :md="12" class="col-info">
                  <div class="avatar-info">
                    <p class="font-semibold m-0" style="font-size: 14px;font-weight: 600;color: #141414;">👀 交易员本金监控</p>
                  </div>
                </a-col>
                <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
                  <small class="mr-5" v-if="balance_monitor_visible === false">关闭</small>
                  <small class="mr-5" v-if="balance_monitor_visible === true">开启</small>
                  <a-switch v-model="balance_monitor_visible" @change="onChangeMode"/>
                </a-col>
                <a-col :span="14" v-if="balance_monitor_visible">
                  <a-form-item class="mb-10" label="最低本金金额" :colon="false"  style="width: 400px">
                    <a-input placeholder="eg. 2000（本金低于2000U时，拒绝一切新开仓交易）"
                             v-decorator="['balance_monitor_value',{ rules: [
                               { required: true, message: '监控最低本金金额？' },
                               { validator: (rule, value, callback) => {
                                  // 检查是否是正整数并在 0 到 50 之间
                                  const isPositiveInteger = /^\d+$/.test(value);
                                  const numberValue = parseInt(value, 10);
                                  if (!isPositiveInteger || numberValue <= 100) {
                                    callback('请输入大于 100 的整数！');
                                  } else {
                                    callback();
                                  }
                                },
                                trigger: 'change'
                              },
                            ]
                           },]"/>
                  </a-form-item>
                </a-col>
              </a-row>

              <a-row type="flex" align="middle" style="margin-top: 20px;">
                <a-col :span="24" :md="12" class="col-info">
                  <div class="avatar-info">
                    <p class="font-semibold m-0" style="font-size: 14px;font-weight: 600;color: #141414;">⚪️ 跟单币种白名单</p>
                  </div>
                </a-col>
                <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
                  <small class="mr-5" v-if="white_list_visible === false">关闭</small>
                  <small class="mr-5" v-if="white_list_visible === true">开启</small>
                  <a-switch v-model="white_list_visible" @change="onChangeMode"/>
                </a-col>
                <a-col :span="14" v-if="white_list_visible">
                  <a-form-item class="tags-field mb-10" label="输入允许跟单的币种" :colon="false">
                    <a-select mode="tags" v-decorator="['white_list']" style="width: 170%" placeholder="eg. BTC（跟BTC-USDT-SWAP）" @change="handleWhiteTagChange">
                      <a-select-option v-for="tag in white_tags" :key="tag" :value="tag" selected>
                        {{ tag }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
              </a-row>


              <a-row type="flex" align="middle" style="margin-top: 20px;">
                <a-col :span="24" :md="12" class="col-info">
                  <div class="avatar-info">
                    <p class="font-semibold m-0" style="font-size: 14px;font-weight: 600;color: #141414;">⚫️ 跟单币种黑名单</p>
                  </div>
                </a-col>
                <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
                  <small class="mr-5" v-if="black_list_visible === false">关闭</small>
                  <small class="mr-5" v-if="black_list_visible === true">开启</small>
                  <a-switch v-model="black_list_visible" @change="onChangeMode"/>
                </a-col>
                <a-col :span="14" v-if="black_list_visible">
                  <a-form-item class="tags-field mb-10" label="输入禁止跟单的币种" :colon="false">
                    <a-select mode="tags" v-decorator="['black_list']" style="width: 170%" placeholder="eg. BTC（不跟BTC-USDT-SWAP）" @change="handleBlackTagChange">
                      <a-select-option v-for="tag in black_tags" :key="tag" :value="tag" selected>
                        {{ tag }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
              </a-row>
            </a-col>
            <!-- 更多高级配置 -->
            <a-col :span="12" style="margin-top: 20px;">
              <a-button type="primary" class="px-25" @click="submit()" style="width: 100%">立即跟单</a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>


    </a-card>
  </div>
</template>

<script>
import http from "../utils/http"

const key = 'updatable';

const columns = [
  {
    title: '交易员',
    dataIndex: 'leader',
    scopedSlots: { customRender: 'leader' },
  },
  {
    title: '账户信息',
    dataIndex: 'api',
    scopedSlots: { customRender: 'api' },
  },
  {
    title: '实盘状态',
    dataIndex: 'info',
    scopedSlots: { customRender: 'info' },
  }
];

function transformItem(item) {
  return {
    key: item.id,
    leader: {
      leaderId: item.leaderId,
      leaderName: item.leaderName,
      slogen: item.slogen,
      img: item.img,
    },
    api: {
      balance: item.balance,
      exchange: item.exchange,
      exchImage: item.exchImage,
    },
    info: {
      status: item.status,
      statusStr: item.statusStr,
    }
  }
}

export default {
  name: "Bicoin",
  components: {
  },
  data() {
    return {
      phone: '',
      password: '',

      isEditable: false,
      buttonText: '保存',
      isNewUser: true,  // 新增一个变量来跟踪是否是新用户
      // 提交状态
      isLoading: false,

      // 币coin用户列表
      columns,
      leader_list: [],
      // rowSelection,
      selectedRowKeys: [],

      // 任务参数
      form: null,
      user: '',
      uniqueName: '', // leader_id

      // 控制组件
      // 非表单字段的数据
      showLess: false,
      apiOptions: [],  // 用于存储选项数据
      // 控制组件显示的变量
      multiple_visible: false, // 多倍模式开关
      posSide_set_visible: false,  // 反向跟单开关
      fast_mode_visible: true,  // 极速跟单开关
      trade_trigger_visible: false,  // 跟单触发模式(单笔止盈止损)
      pos_visible: false,  // 多空策略
      vol24h_visible: false,  // 24h成交量排行榜跟单开关
      balance_monitor_visible: false,  // 交易员本金监控
      white_list_visible: false,  //跟单白名单
      black_list_visible: false,  //跟单黑名单
      white_list: [],
      black_list: [],
      white_tags: ['BTC', 'ETH', 'SOL', 'PEPE'],  // 初始标签列表
      black_tags: ['BTC', 'ETH', 'SOL', 'PEPE'],  // 初始标签列表

    };
  },
  created() {
    this.fetchUserData();
    this.fetchApiOptions();

    this.form = this.$form.createForm(this);
    this.form.setFieldsValue({
        role_type: '1',
        id: null, // 跟单 API ID
        follow_type: '2',  // 跟单模式
        sums: '0',  // 单笔跟单金额
        ratio: '0',  // 固定比例跟单
        investment: '0',  // 投资额
        lever_set: '2',  // 自定义杠杆设置
        leverage: '1',  // 自定义杠杆
        reduce_ratio: '0',  // 减仓比例
        first_open_type: '2',  // 开仓模式
        uplRatio: '0',  //交易员当前收益率
        first_order_set: '1',  // 首单跟单设置
        posSide_set: '1',  // 反向跟单设置
        fast_mode: '1',  // 极速跟单
        trade_trigger_mode: '0',  // 跟单触发模式(单笔止盈止损)
        sl_trigger_px: '0',  // 止损价百分比
        tp_trigger_px: '0',  // 止盈价百分比
        pos_mode: '0',  // 多空策略
        pos_value: '',  // 多空策略
        balance_monitor_mode: '0',  // 交易员本金监控
        balance_monitor_value: '0',
        vol24h_mode: '0',  // 24h成交量排行榜跟单模式
        vol24h_num: '0',
        white_list_mode: '0',  //跟单白名单
        white_list: [],
        black_list_mode: '0',  //跟单黑名单
        black_list: [],
      });
  },
  methods: {
    // 获取用户币coin账户信息，添加修改账户
    async fetchUserData() {
      try {
        const response = await http.get('/api/bicoin/?token=' + localStorage.getItem("token"));
        if (response.data.code === 0) {
          this.phone = response.data.data.phone;
          this.password = response.data.data.password;
          this.isEditable = false;
          this.buttonText = '修改';
          this.isNewUser = false;
        } else {
          this.isEditable = true;
          this.isNewUser = true;
        }
      } catch (error) {
        console.error('获取用户数据失败:', error);
        this.isEditable = true;
        this.isNewUser = true;
      }
    },
    async handleButtonClick() {
      try {
        if (this.isEditable) {
          await this.updateUserData();
        } else {
          this.isEditable = true;
          this.buttonText = '保存';
        }
      } catch (error) {
        console.error("处理时发生错误:", error);
        this.$message.error({ content: '网络请求失败，请联系管理员', key, duration: 2 });
      }
    },
    // 更新用户币coin账号
    async updateUserData() {
      this.isLoading = true;
      this.$message.loading({ content: '币coin校验中，请稍后...', key, duration: 0 });
      try {
        const response = await http.patch('/api/bicoin/?token=' + localStorage.getItem("token"), {
          phone: this.phone,
          password: this.password,
        });

        if (response.data.code === 0) {
          this.isEditable = false;
          this.buttonText = '修改';
          this.$message.success({ content: '修改成功', key, duration: 2 });
        } else {
          this.$message.error({ content: response.data.error, key, duration: 2 });
        }
      } catch (error) {
        console.error('更新用户数据失败:', error);
        this.$message.error({ content: '更新用户时发生错误', key, duration: 2 });
      } finally {
        this.isLoading = false;
      }
    },

    // 搜索
    async onSearch(value) {
      this.$message.loading({ content: '币coin搜索中，请稍后...', key, duration: 0 });
      try {
        const response = await http.post('/api/bicoin/?token=' + localStorage.getItem("token"), {
          search_name: value,
        });

        if (response.data.code === 0) {
          // 更新用户数据
          const data = response.data.data;
          this.leader_list = data.map(item => transformItem(item));

          this.$message.success({ content: '数据加载成功', key, duration: 2 });
        } else {
          this.$message.error({ content: response.data.error, key, duration: 2 });
        }
      } catch (error) {
        this.$message.error({ content: '暂无数据', key, duration: 2 });
      }
    },

    // 选择交易员
    onSelect(record, selected) {
      if (selected) {
        this.selectedRowKeys = [record.key];
        this.uniqueName = record.leader.leaderId
        console.log('Selected record:', record);
      } else {
        this.selectedRowKeys = [];
        this.form.setFieldsValue({ uniqueName: '' }); // 可选：清空 uniqueName 字段
      }
    },

    onChange(selectedRowKeys) {
      if (selectedRowKeys.length > 1) {
        this.selectedRowKeys = [selectedRowKeys[selectedRowKeys.length - 1]];
      } else {
        this.selectedRowKeys = selectedRowKeys;
      }
      console.log('Selected row keys:', this.selectedRowKeys);
    },

    // 基础配置
    // 获取跟单API信息
    async fetchApiOptions() {
      try {
        const response = await http.get('/api/apiadd/?token=' + localStorage.getItem("token"));
        console.log("API Response:", response.data);
        if (response.data && Array.isArray(response.data.data)) {
          this.user = response.data.data[0].user;
          this.apiOptions = response.data.data.filter(item => item.status === 1 && item.is_readonly === false).map(item => ({
            api_name: item.api_name,
            id: item.id
          }));
        } else {
          console.error("预期的数据格式不正确");
        }
      } catch (error) {
        console.error("获取数据失败:", error);
      }
    },

    // 更多配置
    toggleShowLess(e) {
      this.showLess = !this.showLess;
    },
    onChangeMode() {
      // 更新 posSide_set
      if (this.posSide_set_visible) {
        this.form.setFieldsValue({ posSide_set: '2' });
      } else {
        this.form.setFieldsValue({ posSide_set: '1' });
      }

      // 更新 fast_mode
      if (this.fast_mode_visible) {
        this.form.setFieldsValue({ fast_mode: '1' });
      } else {
        this.form.setFieldsValue({ fast_mode: '0' });
      }

      // 更新 trade_trigger_mode
      if (this.trade_trigger_visible) {
        this.form.setFieldsValue({ trade_trigger_mode: '1' });
      } else {
        this.form.setFieldsValue({ trade_trigger_mode: '0' });
      }
    },

    handleWhiteTagChange(value) {
      // 过滤掉黑名单中的标签
      const filteredTags = value.filter(tag => {
        if (this.black_list.includes(tag)) {
          this.$message.error(`标签 "${tag}" 已存在于黑名单中，不能添加到白名单`);
          return false; // 如果标签在黑名单中，则过滤掉
        }
        return true; // 否则保留标签
      });

      // 更新 white_list
      this.white_list = filteredTags;

      // 打印调试信息
      // console.log("白名单标签:", this.white_list);
    },
    handleBlackTagChange(value) {
      // 过滤掉白名单中的标签
      const filteredTags = value.filter(tag => {
        if (this.white_list.includes(tag)) {
          this.$message.error(`标签 "${tag}" 已存在于白名单中，不能添加到黑名单`);
          return false; // 如果标签在白名单中，则过滤掉
        }
        return true; // 否则保留标签
      });

      // 更新 black_list
      this.black_list = filteredTags;

      // 打印调试信息
      // console.log("黑名单标签:", this.black_list);
    },

    // 提交任务信息
    submit() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          try {
            const data = {
              trader_platform: 3,
              uniqueName: this.uniqueName,
              api: values.id,
              follow_type: values.follow_type,
              sums: values.sums,
              multiple: values.multiple,
              ratio: values.ratio,
              lever_set: values.lever_set,
              leverage: values.leverage,
              first_open_type: values.first_open_type,
              uplRatio: values.uplRatio,
              first_order_set: values.first_order_set,
              posSide_set: this.posSide_set_visible ? '2' : '1',
              user: this.user, // 确保 this.user 已在组件中定义
              role_type: values.role_type,
              reduce_ratio: values.reduce_ratio,
              fast_mode: this.fast_mode_visible ? '1' : '0',
              investment: values.investment,
              trade_trigger_mode: this.trade_trigger_visible ? '1' : '0',
              sl_trigger_px: values.sl_trigger_px,
              tp_trigger_px: values.tp_trigger_px,
              pos_mode: this.pos_visible ? '1' : '0',
              pos_value: values.pos_value,
              balance_monitor_mode: this.balance_monitor_visible ? '1' : '0',
              balance_monitor_value: values.balance_monitor_value,
              vol24h_mode: this.vol24h_visible ? '1' : '0',  // 24h成交量排行榜跟单模式
              vol24h_num: values.vol24h_num,
              white_list_mode: this.white_list_visible ? '1' : '0',  //跟单白名单
              white_list: this.white_list,
              black_list_mode: this.black_list_visible ? '1' : '0',  //跟单黑名单
              black_list: this.black_list,
            };

            const response = await http.post(
                '/api/taskadd/?token=' + localStorage.getItem('token'),
                data
            );
            if (response.data && response.data.code === 0) {
              this.$message.success('创建成功');
              this.$router.push({ path: '/tasklist' });

              try {
                await http.patch(
                    '/api/apiadd/' + values.id + '/?token=' + localStorage.getItem('token'),
                    { status: 2 }
                );
              } catch (patchError) {
                console.error('更新状态时发生错误:', patchError);
                // 处理 http.patch 请求的错误
              }
            } else if (response.data && response.data.code === 1001) {
              this.$message.error(response.data.error);
              this.loading = false;
            } else {
              this.$message.error('创建失败');
              this.loading = false;
            }
          } catch (error) {
            console.error('提交时发生错误:', error);
            this.loading = false;
            // 处理错误
          }
        } else {
          // 处理表单验证错误
          console.error('表单验证错误:', err);
          this.$message.error('请检查表单输入是否正确');
        }
      });
    },

  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onChange,
        onSelect: this.onSelect,
        type: 'radio', // 确保只选择一行
      };
    },
  },
};
</script>


<style lang="scss">
.col-gallery {
  .gallery-img {
    border-radius: 12px;
  }

  .pswipe-gallery {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;

    img {
      height: 100px;
      width: 100px;
      border-radius: 12px;
      //box-shadow: $shadow-3;
    }
  }
}

.img-contain {
  max-width: 100%;
}

.mb-10 {
  font-weight: 600;
}

.form-tag.ant-tag {
  border-radius: 20px;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 3.75px;
  margin-bottom: 3.75px;
  background-color: #3a416f;
  border: 1px solid #3a416f;
  color: #fff;
}
.form-tag.ant-tag .anticon-close {
  color: #fff;
  height: 16px;
  border-left: 1px solid hsla(0,0%,100%,.3);
  padding-left: 5px;
  padding-top: 2px;
  opacity: .75;
}
.form-tag.ant-tag .anticon-close:hover {
  color: #fff;
  opacity: 1;
}
.tags-field .ant-input {
  margin-bottom: 5px;
  margin-top: 4px;
}

.tags-field .ant-select {
  .ant-select-selection__choice__remove i {
    color: #fff;
    height: 16px;
    border-left: 1px solid hsla(0, 0%, 100%, .3);
    padding-left: 5px;
    padding-top: 2px;
    opacity: .75;

    &:hover {
      color: #fff;
      opacity: 1;
    }
  }

  .ant-select-selection__rendered > ul > li:not(.ant-select-search) {
    border-radius: 20px;
    padding: 2px 27px 2px 10px;
    font-size: 12px;
    font-weight: 500;
    margin-right: 3.75px;
    margin-bottom: 3.75px;
    background-color: #3a416f;
    border: 1px solid #3a416f;
    color: #fff;
    line-height: 2;
    height: 30px;
  }

  .ant-select-selection--multiple {
    padding: 8px 10px;
  }
}
</style>