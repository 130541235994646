<template>
  <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
    <template #title>
      <a-row type="flex" align="middle">
        <a-col :span="24" :md="12">
          <h5 class="font-semibold m-0">极速跟单</h5>
        </a-col>
        <a-col :span="24" :md="12">
        </a-col>
      </a-row>
    </template>

    <a-form
        :form="form"
        class="mt-30"
        :hideRequiredMark="true"
        style="padding: 0 24px;"
    >
      <a-row type="flex" :gutter="[24]">
        <a-col :span="24" :md="20" :lg="18" class="mx-auto">
          <a-row class="checkbox-group" type="flex" :gutter="[50]">
            <a-col :span="12">
              <div class="checkbox-label"
                   :class="[selectedOption === 'API跟单' ? 'active' : '']"
                   @click="toggleCheckbox('API跟单')"
              >
                <h6>API跟单</h6>
              </div>
            </a-col>
            <a-col :span="12">
              <div class="checkbox-label"
                   :class="[selectedOption === '共享仓位' ? 'active' : '']"
                   @click="toggleCheckbox('共享仓位')"
              >
                <h6>共享仓位</h6>
              </div>
            </a-col>
          </a-row>
          <a-radio-group class="d-none" v-model="selectedOption" :options="checkboxOptions"/>
        </a-col>
      </a-row>


      <a-row :gutter="[0, 24]" type="flex" justify="center" style="margin-top: 46px;" v-if="selectedOption == 'API跟单'">
        <!--搜索框-->
        <a-input-search
            placeholder="输入API名称"
            enter-button="搜索"
            @search="apiSearch"
            style="width: 74%"
            size="large"
            allow-clear
        />
        <!--搜索框-->

        <!--提示-->
        <a-row type="flex" align="top" style="margin: 30px 0;flex-direction: column;" v-if="api_list.length==0">
          <p class="text-gray-7" style="font-weight: 400; font-size: 12px; margin-top: 0;">
            <a-icon type="exclamation-circle" theme="outlined" />
            API跟单说明：
          </p>
          <p class="text-gray-7" style="font-weight: 400; font-size: 12px; margin-top: 4px;">
            当前仅支持OKX，币安正在开发中...
          </p>
          <p class="text-gray-7" style="font-weight: 400; font-size: 12px; margin-top: 4px;">
            搜索并选择你想跟单的API。当该API产生交易时，你的跟单账号也将同步进行交易。
          </p>
          <p class="text-gray-7" style="font-weight: 400; font-size: 12px; margin-top: 4px;">
            使用API进行跟单，效果堪比在交易所跟单，使用官方接口实时交易。
          </p>
          <p class="text-gray-7" style="font-weight: 400; font-size: 12px; margin-top: 0;">
            <a-icon type="check-circle" theme="outlined" />
            使用技巧：
          </p>
          <p class="text-gray-7" style="font-weight: 400; font-size: 12px; margin-top: 4px;">
            - 以OKX交易所为例，可以使用自己的小号在交易所进行跟单，可同时跟单一个或多个交易员。
          </p>
          <p class="text-gray-7" style="font-weight: 400; font-size: 12px; margin-top: 4px;">
            将小号的API提交到跟单猿，使用大号跟随小号，将实现0分润实时跟单。效果和在交易所跟单完全一致！
          </p>
          <p class="text-gray-7" style="font-weight: 400; font-size: 12px; margin-top: 4px;">
            此方法币安不适用！
          </p>
          <p class="text-gray-7" style="font-weight: 400; font-size: 12px; margin-top: 4px;">
            - 针对社区运营者，可将自己的API提交到跟单猿，让他人通过API跟单，跟随您的API进行交易。
          </p>
          <p class="text-gray-7" style="font-weight: 400; font-size: 12px; margin-top: 4px;">
            既可实现私域带单，同时也可避免被交易所或他人监控。
          </p>
        </a-row>
        <!--提示-->

        <!--搜索结果-->
        <a-table
            :columns="api_columns"
            :data-source="api_list"
            :pagination="false"
            style="width: 74%;margin-top: 30px;margin-bottom: 30px;border: 1px solid #ddd;border-radius: 8px;"
            v-else
        >

          <template slot="api_name" slot-scope="api_name">
            {{api_name}}
          </template>

          <template slot="exchange" slot-scope="exchange">
            <a-space :size="-12" class="avatar-chips">
              <a-avatar size="small" :src="exchange" />
            </a-space>
          </template>

          <template slot="user_id" slot-scope="username">
            {{ username }}
          </template>

          <template slot="usdt" slot-scope="usdt">
            <span :style="{ color: '#31bd65', 'font-weight': '600' }">{{ usdt }} USDT</span>
          </template>

          <template slot="api_id" slot-scope="api_id">
            <a-button type="primary" size="small" :data-id="api_id" v-if="!vip || $store.state.userInfo.is_vip || api_id===2" @click="taskadd(api_id, 6)">
              立即跟单
            </a-button>
            <a-button type="danger" size="small" v-else-if="vip && !$store.state.userInfo.is_vip" @click="goToPricing">
              仅支持VIP跟单
            </a-button>
          </template>

        </a-table>
        <!--搜索结果-->
      </a-row>

      <a-row :gutter="[0, 24]" type="flex" justify="center" style="margin-top: 46px;" v-if="selectedOption == '共享仓位'">
        <!--搜索框-->
        <a-input-search
            placeholder="输入共享仓位账号名称"
            enter-button="搜索"
            @search="curlSearch"
            style="width: 74%"
            size="large"
            allow-clear
        />
        <!--搜索框-->

        <!--提示-->
        <a-row type="flex" justify="center" style="margin: 30px 0;flex-direction: column;" v-if="curl_list.length==0">
          <p class="text-gray-7" style="font-weight: 400; font-size: 12px; margin-top: 0;">
            <a-icon type="exclamation-circle" theme="outlined" />
            共享仓位跟单说明：
          </p>
          <p class="text-gray-7" style="font-weight: 400; font-size: 12px; margin-top: 4px;">
            此跟单模式目前仅适用于币安。
          </p>
          <p class="text-gray-7" style="font-weight: 400; font-size: 12px; margin-top: 4px;">
            使用小号在币安交易所跟单后，可获取带单项目的实时仓位信息，公域带单和私域带单都适用。
          </p>
          <p class="text-gray-7" style="font-weight: 400; font-size: 12px; margin-top: 4px;">
            需要每4天更新一次小号的cookie信息，否则无法获取实时仓位信息。超过4天未更新cookie将无法继续跟单。
          </p>
          <p class="text-gray-7" style="font-weight: 400; font-size: 12px; margin-top: 4px;">
            每次更需要重新登陆币安账号进行获取。
          </p>
          <p class="text-gray-7" style="font-weight: 400; font-size: 12px; margin-top: 0;">
            <a-icon type="check-circle" theme="outlined" />
            使用技巧：
          </p>
          <p class="text-gray-7" style="font-weight: 400; font-size: 12px; margin-top: 4px;">
            可以将自己账号的仓位信息和他人共享，让别人通过跟极速跟单功能索你的账号进行跟单交易。
          </p>
          <p class="text-gray-7" style="font-weight: 400; font-size: 12px; margin-top: 4px;">
            期间你需要保持账号的Cookie信息有效，且一直处于交易所的跟单项目中，否则将无法持续跟单。
          </p>
          <p style="font-weight: 600; font-size: 12px; margin-top: 4px;color: #eb4b6d;">
            <a-icon type="question-circle" theme="outlined" />
            找不到账号 / 账号已失效：
          </p>
          <p style="font-weight: 600; font-size: 12px; margin-top: 4px;color: #eb4b6d;">
            添加/更新Cookie信息：<router-link to="/speedcopy/curl">点击前往</router-link>
          </p>
        </a-row>
        <!--提示-->

        <!--搜索结果-->
        <a-table
            :columns="curl_columns"
            :data-source="curl_list"
            :pagination="false"
            style="width: 74%;margin-top: 30px;margin-bottom: 30px;border: 1px solid #ddd;border-radius: 8px;"
            v-else
        >

          <template slot="curl_name" slot-scope="curl_name">
            {{curl_name}}
          </template>

          <template slot="exchange" slot-scope="exchange">
            {{exchange}}
          </template>

          <template slot="user_id" slot-scope="username">
            {{username}}
          </template>

          <template slot="updated_at" slot-scope="updated_at">
            {{updated_at}}
          </template>

          <template slot="status" slot-scope="status">
            <div v-if="!status.available">
              <a-tag class="tag-status ant-tag-red">
                已失效
              </a-tag>
            </div>
            <div v-else>
              <a-button type="primary" size="small" :data-id="status.curl_id" v-if="!vip || $store.state.userInfo.is_vip" @click="taskadd(status.curl_id, status.exchange === '币安Binance'? 7 : 8)">
                立即跟单
              </a-button>
              <a-button type="danger" size="small" v-else-if="vip && !$store.state.userInfo.is_vip" @click="goToPricing">
                仅支持VIP跟单
              </a-button>
            </div>
          </template>

        </a-table>
        <!--搜索结果-->

      </a-row>
    </a-form>

  </a-card>

</template>

<script>
import http from "../utils/http"
import { message } from 'ant-design-vue';

const key = 'updatable';

const curl_columns = [
  {
    title: '名称',
    dataIndex: 'curl_name',
    scopedSlots: { customRender: 'curl_name' },
  },
  {
    title: '交易所',
    dataIndex: 'exchange',
    scopedSlots: { customRender: 'exchange' },
  },
  {
    title: '所有者',
    dataIndex: 'username',
    scopedSlots: { customRender: 'username' },
  },
  {
    title: '上次更新时间',
    dataIndex: 'updated_at',
    scopedSlots: { customRender: 'updated_at' },
  },
  {
    title: '',
    dataIndex: 'status',
    scopedSlots: {customRender: 'status'},
    width: 50,
  },
]

function transformCurlItem(item) {
  if(item.exchange === 1){
    item.exchange = '欧意OKX';
  }else if (item.exchange === 2){
    item.exchange = '币安Binance';
  }
  return {
    curl_name: item.curl_name,
    exchange: item.exchange,
    user_id: item.user_id,
    username: item.username,
    updated_at: item.updated_at,
    status: {
      curl_id: item.curl_id,
      exchange: item.exchange,
      available: item.available,
    },
  }
}

const api_columns = [
  {
    title: 'API名称',
    dataIndex: 'api_name',
    scopedSlots: { customRender: 'api_name' },
  },
  {
    title: '交易所',
    dataIndex: 'exchange',
    scopedSlots: { customRender: 'exchange' },
  },
  // {
  //   title: 'UID',
  //   dataIndex: 'user_id',
  //   scopedSlots: { customRender: 'user_id' },
  // },
  {
    title: '所有者',
    dataIndex: 'username',
    scopedSlots: { customRender: 'username' },
  },
  {
    title: '资金',
    dataIndex: 'usdt',
    scopedSlots: { customRender: 'usdt' },
  },
  {
    title: '',
    dataIndex: 'api_id',
    scopedSlots: {customRender: 'api_id'},
    width: 50,
  },
];

function transformApiItem(item) {
  let imagePath;
  switch(item.exchange) {
    case 1:
      imagePath = 'images/okx.png';
      break;
    case 2:
      imagePath = 'images/binance.png';
      break;
    default:
      imagePath = 'images/okx.png'; // 默认图片
  }
  return {
    api_name: item.api_name,
    api_id: item.api_id,
    user_id: item.user_id,
    username: item.username,
    exchange: imagePath,
    usdt: item.usdt,
  }
}

export default {
  name: "SpeedCopy",
  components: {},
  data() {
    return {
      vip: true,
      // 选择模式
      selectedOption: 'API跟单',

      // Checkbox'es array of all options.
      checkboxOptions: ['API跟单', '共享仓位', '更多'],
      // Step's form object
      form: this.$form.createForm(this, {name: 'SpeedCopy'}),

      // api搜索
      api_columns,
      api_list: [],

      // curl搜索
      curl_columns,
      curl_list: [],

      // 提交状态
      isLoading: false,
    }
  },
  methods: {
    // Toggle an item from the checkbox.
    toggleCheckbox(item) {
      if (item === '更多') {
        this.selectedOption = 'API跟单';
        return;
      } else {
        this.selectedOption = item;
        return;
      }
    },
    // toplatform(selectedOption) {
    //   if (selectedOption === 'API跟单') {
    //     return '1';
    //   } else if (selectedOption === '共享仓位') {
    //     return '2';
    //   } else if (selectedOption === '更多') {
    //     return '3';
    //   }
    // },

    // 搜索api
    async apiSearch(value) {
      if (value.length === 0 || value === '') {
        this.api_list = [];
        return;
      }
      this.$message.loading({ content: 'API搜索中，请稍后...', key, duration: 0 });
      try {
        const response = await http.post('/api/speedtrade/apisearch/?token=' + localStorage.getItem("token"), {
          search_name: value,
        });

        if (response.data.code === 0) {
          // 更新用户数据
          const data = response.data.data;
          this.api_list = data.filter(item => item.exchange === 1).map(item => transformApiItem(item));

          this.$message.success({ content: '数据加载成功', key, duration: 2 });
        } else {
          this.$message.error({ content: response.data.error, key, duration: 2 });
        }
      } catch (error) {
        this.$message.error({ content: '暂无数据', key, duration: 2 });
      }
    },
    // 搜索curl
    async curlSearch(value) {
      if (value.length === 0 || value === '') {
        this.curl_list = [];
        return;
      }
      this.$message.loading({ content: '搜索中，请稍后...', key, duration: 0 });
      try {
        const response = await http.post('/api/speedtrade/curlsearch/?token=' + localStorage.getItem("token"), {
          search_name: value,
        });

        if (response.data.code === 0) {
          // 更新用户数据
          const data = response.data.data;
          this.curl_list = data.map(item => transformCurlItem(item));

          this.$message.success({ content: '数据加载成功', key, duration: 2 });
        } else {
          this.$message.error({ content: response.data.error, key, duration: 2 });
        }
      } catch (error) {
        this.$message.error({ content: '暂无数据', key, duration: 2 });
      }
    },

    // 跳转vip
    goToPricing(){
      this.$router.push({path:'/pricing'})
    },

    // 立即跟单
    taskadd(id, trader_platform) {
      // 导航到 Task-Add 页面
      this.$router.push({
        path: `/speedcopy/taskadd/${trader_platform}/${id}`,
      });
    },
  },

}
</script>

<style scoped>
.mt-30 {
  font-weight: 600;
}



</style>